export default [
  {
    path: '/app/main',
    name: 'app-main',
    component: () => import('@/views/apps/Main.vue'),
    meta: {
      layout: 'main',
      wrapType: 'main',
    },
  },
  {
    path: '/app/druginfo/main',
    name: 'app-druginfo-main',
    component: () => import('@/views/apps/druginfo/DrugInfo.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/medical-trend/main',
    name: 'app-medical-trend-main',
    component: () => import('@/views/apps/medical-trend/MedicalTrend.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/notice/main',
    name: 'app-notice-main',
    component: () => import('@/views/apps/notice/Notice.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/event/main',
    name: 'app-event-main',
    component: () => import('@/views/apps/event/EventList.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/point-mall/main',
    name: 'app-point-mall-main',
    component: () => import('@/views/apps/point-mall/PointMall.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/survey/main',
    name: 'app-survey-main',
    component: () => import('@/views/apps/survey/SurveyList.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/webseminar/',
    name: 'app-webseminar-main',
    component: () => import('@/views/apps/webseminar/WebSeminar.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
    redirect: "/app/webseminar/client",
    children: [
      {
        path: 'client',
        name: 'app-webseminar-client',
        component: () => import('@/views/apps/webseminar/seminar/ClientSeminar.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
        },
      },
      {
        path: 'kma',
        name: 'app-webseminar-kma',
        component: () => import('@/views/apps/webseminar/seminar/KmaSeminar.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
        },
      },
      {
        path: 'my',
        name: 'app-webseminar-my',
        component: () => import('@/views/apps/webseminar/seminar/MySeminar.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
    ]
  },
  {
    path: '/app/mypage/',
    name: 'app-mypage-main',
    component: () => import('@/views/apps/mypage/MyPage.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
      isAuth: true,
    },
    redirect: "/app/mypage/profile",
    children: [
      {
        path: 'profile',
        name: 'app-mypage-profile',
        component: () => import('@/views/apps/mypage/profile/UserProfile.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
      {
        path: 'withdrawal',
        name: 'app-mypage-withdrawal',
        component: () => import('@/views/apps/mypage/profile/UserWithdrawal.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
      {
        path: 'passwordChange',
        name: 'app-mypage-passwordChange',
        component: () => import('@/views/apps/mypage/password/PasswordChange.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
      {
        path: 'actHistory',
        name: 'app-mypage-actHistory',
        component: () => import('@/views/apps/mypage/act-history/ActHistory.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
      {
        path: 'askHistory',
        name: 'app-mypage-askHistory',
        component: () => import('@/views/apps/mypage/ask-history/AskHistory.vue'),
        meta: {
          layout: 'main',
          wrapType: 'sub',
          isAuth: true,
        },
      },
    ],
  },
  {
    path: '/service-term',
    name: 'app-terms-service-term',
    component: () => import('@/views/apps/terms/TotalServiceTerms.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/privacy-term',
    name: 'app-terms-privacy-term',
    component: () => import('@/views/apps/terms/PrivacyProcessTerms.vue'),
    meta: {
      layout: 'main',
      wrapType: 'sub',
    },
  },
  {
    path: '/service',
    name: 'app-terms-service',
    component: () => import('@/views/apps/terms/TotalServiceTerms.vue'),
    meta: {
      layout: 'popup',
    },
  },
  {
    path: '/privacy',
    name: 'app-terms-privacy',
    component: () => import('@/views/apps/terms/PrivacyProcessTerms.vue'),
    meta: {
      layout: 'popup',
    },
  },
  {
    path: '/app/preview',
    name: 'app-preview',
    component: () => import('@/views/apps/preview/Preview.vue'),
    meta: {
      layout: 'empty',
      wrapType: 'sub',
    },
  },
  {
    path: '/app/preview-survey',
    name: 'app-preview-survey',
    component: () => import('@/views/apps/preview/PreviewSurvey.vue'),
    meta: {
      layout: 'empty',
      wrapType: 'sub',
    },
  },
]
