export default [
  {
    path: '/login',
    name: 'login-main',
    component: () => import('@/views/pages/account/Login.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/member-join',
    name: 'member-join-main',
    component: () => import('@/views/pages/account/MemberJoin.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/pw-reset',
    name: 'pw-reset-main',
    component: () => import('@/views/pages/account/ResetPassword.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/pw-find',
    name: 'pw-find-main',
    component: () => import('@/views/pages/account/FindPassword.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/verification/result',
    name: 'verification-result',
    component: () => import('@/views/pages/verification/VerificationResult.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/not-accessible',
    name: 'not-accessible',
    component: () => import('@/views/pages/miscellaneous/NotAccessible.vue'),
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/error/Error404.vue'),
    meta: {
      layout: 'empty',
    },
  },
]
