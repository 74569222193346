export default {
  namespaced: true,
  state: {
    infoSetting: null,
    medicalDept: null,
    userName: null,
    hName: null,
    hAddress: null,
    licenseNum: null,
    shaEnLicenseNum: null,
    shaYoyangNum: null,
    refreshTokenSetting: null,
    region: null,
  },
  getters: {
    getInfoSetting: state => state.infoSetting,
    getMedicalDept: state => state.medicalDept,
    getUserName: state => state.userName,
    getHName: state => state.hName,
    getHAddress: state => state.hAddress,
    getLicenseNum: state => state.licenseNum,
    getShaEnLicenseNum: state => state.shaEnLicenseNum,
    getShaYoyangNum: state => state.shaYoyangNum,
    getRefreshTokenSetting: state => state.refreshTokenSetting,
    getRegion: state => state.region,
  },
  mutations: {
    setInfoSetting(state, infoSetting) {
      state.infoSetting = infoSetting
    },
    setMedicalDept(state, medicalDept) {
      state.medicalDept = medicalDept
    },
    setUserName(state, userName) {
      state.userName = userName
    },
    setHName(state, hName) {
      state.hName = hName
    },
    setHAddress(state, hAddress) {
      state.hAddress = hAddress
    },
    setLicenseNum(state, licenseNum) {
      state.licenseNum = licenseNum
    },
    setShaEnLicenseNum(state, shaEnLicenseNum) {
      state.shaEnLicenseNum = shaEnLicenseNum
    },
    setShaYoyangNum(state, shaYoyangNum) {
      state.shaYoyangNum = shaYoyangNum
    },
    setRefreshTokenSetting(state, refreshTokenSetting) {
      state.refreshTokenSetting = refreshTokenSetting
    },
    setRegion(state, region) {
      state.region = region
    },
    clear(state) {
      state.infoSetting = null
      state.medicalDept = null
      state.userName = null
      state.hName = null
      state.hAddress = null
      state.licenseNum = null
      state.shaEnLicenseNum = null
      state.shaYoyangNum = null
      state.refreshTokenSetting = null
      state.region = null
    },
  },
  actions: {},
}
