<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const MoaLayout = () => import('./components/layouts/MoaLayout.vue')
const MainLayout = () => import('./components/layouts/MainLayout.vue')
const EmptyLayout = () => import('./components/layouts/EmptyLayout.vue')
const PageLayout = () => import('./components/layouts/PageLayout.vue')
const PopupLayout = () => import('./components/layouts/PopupLayout.vue')

export default {
  name: 'App',
  components: {
    MoaLayout,
    MainLayout,
    EmptyLayout,
    PageLayout,
    PopupLayout
  },

  computed: {
    layout() {
      return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'empty-layout'
    },
  },

  mounted() {
    // #171811 리소스 파일(이미지 등) 드래그를 통한 카피 방지를 위해
    document.addEventListener('dragstart', (event) => {
      event.preventDefault()
    })
  },

  // 뒤로가기 막기
  // created() {
  //   window.onbeforeunload = null

  //   window.onpopstate = () => {
  //     // eslint-disable-next-line no-restricted-globals
  //     history.go(1)
  //   }
  // },
}
</script>

<style>
</style>
