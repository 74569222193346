export default {
  namespaced: true,
  state: {
    name: null,
    point: null,
    phoneNum: null,
  },
  getters: {
    getName: state => state.name,
    getPoint: state => state.point,
    getPhoneNum: state => state.phoneNum
  },
  mutations: {
    setName(state, name) {
      state.name = name
    },
    setPoint(state, point) {
      state.point = point
    },
    setPhoneNum(state, phoneNum) {
      state.phoneNum = phoneNum
    },
    clear(state) {
      state.name = null
      state.point = null
      state.phoneNum = null
    },
  },
  actions: {},
}
