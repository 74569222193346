import eventBus from '@/libs/event/event-bus'
import { liveInfoEventHandler } from './global-liveinfo'

window.liveInfoCall = (funcName, p) => {
  if (!p) {
    eventBus.$emit(liveInfoEventHandler, { funcName })
  } else {
    eventBus.$emit(liveInfoEventHandler, { funcName, p: JSON.parse(p) })
  }
}
