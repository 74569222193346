import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn, checkAccessible } from '@/auth/utils'
import moa from './routes/moa'
import apps from './routes/apps'
import pages from './routes/pages'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    if (document.querySelector('section.sub-wrap')) document.querySelector('section.sub-wrap').scrollTo(0, 0)
    if (document.querySelector('section.main-wrap')) document.querySelector('section.main-wrap').scrollTo(0, 0)

    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'app-main' } },
    ...moa,
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, _, next) => {
  if (to.name === 'app-preview' || to.name === 'app-preview-survey') {
    return next()
  }

  if (!checkAccessible() && to.name !== 'not-accessible') {
    return next({ name: 'not-accessible' })
  }

  if (to.name === 'verification-result') {
    const { retInfo } = to.query

    store.commit('util/setVerifyResult', retInfo)

    return next(false)
  }

  if (to.name === 'login-main') {
    if (router.app.$store.getters['util/getAccess'] === false) return next({ name: 'pw-reset-main' })
  }

  if (to.meta.isAuth) {
    const isLoggedIn = await isUserLoggedIn()

    if (!isLoggedIn) next({ name: 'login-main', query: { redirect: to.name } })
  }

  return next()
})

export default router
