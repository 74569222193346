import eventBus from '@/libs/event/event-bus'
import { liveInfoEventHandler } from '@/core/global/global-liveinfo'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

// eslint-disable-next-line import/prefer-default-export
export const setInjectInfoData = async () => {
  if (process.env && process.env.NODE_ENV === 'development' && process.env.VUE_APP_LOCAL_ENV) {
    store.commit('infoData/setInfoSetting', true)
    store.commit('infoData/setRefreshTokenSetting', true)
    store.commit('infoData/setMedicalDept', process.env.VUE_APP_YSR_MEDICAL_DEPT)
    store.commit('infoData/setUserName', process.env.VUE_APP_YSR_USER_NAME)
    store.commit('infoData/setHName', process.env.VUE_APP_YSR_HOSP_NAME)
    store.commit('infoData/setHAddress', process.env.VUE_APP_YSR_HOSP_ADDRESS)
    store.commit('infoData/setLicenseNum', process.env.VUE_APP_YSR_LICENSE_NUM)
    store.commit('infoData/setShaEnLicenseNum', process.env.VUE_APP_YSR_SHA_LICENSE_NUM)
    store.commit('infoData/setShaYoyangNum', process.env.VUE_APP_YSR_SHA_YOYANG_NUM)
  }

  eventBus.$on(liveInfoEventHandler, payload => {
    switch (payload.funcName) {
      case 'AppInfoData':
        store.commit('infoData/setInfoSetting', true)
        store.commit('infoData/setMedicalDept', payload.p.subject)
        store.commit('infoData/setUserName', payload.p.userName)
        store.commit('infoData/setHName', payload.p.hName)
        store.commit('infoData/setHAddress', payload.p.address)
        store.commit('infoData/setLicenseNum', payload.p.licenseNum)

        if (payload.p.shaEnLicenseNum.length === 0) store.commit('infoData/setShaEnLicenseNum', null)
        else store.commit('infoData/setShaEnLicenseNum', payload.p.shaEnLicenseNum)

        store.commit('infoData/setShaYoyangNum', payload.p.shaYoyangNum)
        if (payload.p.region) store.commit('infoData/setRegion', payload.p.region)
        break

      case 'InjectRefreshToken':
        // refreshToken 값 존재 여부와 관계없이 호출 된 경우 true
        store.commit('infoData/setRefreshTokenSetting', true)

        if (payload.p.refreshToken) {
          useJwt.setRefreshToken(payload.p.refreshToken)
        }
        break

      default:
        break
    }
  })
}
