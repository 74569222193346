export default {
  namespaced: true,
  state: {
    popupSetting: null,
    params: null,
    join: null,
    access: null,
    verify: null,
    verifyResult: null,
  },
  getters: {
    getPopupSetting: state => state.popupSetting,
    getParams: state => state.params,
    getJoin: state => state.join,
    getAccess: state => state.access,
    getVerify: state => state.verify,
    getVerifyResult: state => state.verifyResult
  },
  mutations: {
    setPopupSetting(state, popupSetting) {
      state.popupSetting = popupSetting
    },
    setParams(state, params) {
      state.params = params
    },
    setJoin(state, join) {
      state.join = join
    },
    setAccess(state, access) {
      state.access = access
    },
    setVerify(state, verify) {
      state.verify = verify
    },
    setVerifyResult(state, verifyResult) {
      state.verifyResult = verifyResult
    },
    clear(state) {
      state.popupSetting = null
      state.params = null
      state.join = null
      state.access = null
      state.verify = null
      state.longTerm = null
      state.verifyResult = null
    },
  },
  actions: {},
}
