import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import account from './account'
import infoData from './liveinfo/infoData'
import util from './util'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    infoData,
    util
  },
  strict: process.env.DEV,
})
