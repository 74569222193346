import store from '@/store'
import axios from '@axios'

// eslint-disable-next-line import/prefer-default-export
export const setStatistics = async (type, serviceId, referenceId = null) => {
  const medicalDept = store.getters['infoData/getMedicalDept']
  const yid = store.getters['infoData/getShaYoyangNum']
  const tid = store.getters['infoData/getShaEnLicenseNum']
  const region = store.getters['infoData/getRegion']

  axios.post('/fu/statistics/save', {
    Type: type,
    MedicalDepartmentCode: medicalDept,
    YID: yid,
    TID: tid,
    PCID: '',
    ServiceId: serviceId,
    ReferenceID: referenceId,
    Region: region
  })
    .then(() => {
    })
    .catch(() => {
    })
}

export const setStatisticsAwait = async (type, serviceId, referenceId = null) => {
  const medicalDept = store.getters['infoData/getMedicalDept']
  const yid = store.getters['infoData/getShaYoyangNum']
  const tid = store.getters['infoData/getShaEnLicenseNum']
  const region = store.getters['infoData/getRegion']

  try {
    const result = await axios.post('/fu/statistics/save', {
      Type: type,
      MedicalDepartmentCode: medicalDept,
      YID: yid,
      TID: tid,
      PCID: '',
      ServiceId: serviceId,
      ReferenceID: referenceId,
      Region: region
    })
    return result.data.id
  } catch (err) {
    return null
  }
}
