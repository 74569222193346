export default {
  // Endpoints
  loginEndpoint: '/fu/auth/login',
  isLoginEndpoint: '/fu/auth/is-login',
  refreshEndpoint: '/fu/auth/refresh-token',
  logoutEndpoint: '/fu/auth/rem-refresh-token',
  registerEndpoint: '/jwt/register',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
