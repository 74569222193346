import AlertDialog from '@/components/controls/AlertDialog.vue'
import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'

export const alerts = {
  components: {
    AlertDialog,
    ConfirmDialog,
  },

  data() {
    return {
      alertProps: {
        show: false,
        message: '',
        submitCallback: null,
      },
      confirmProps: {
        show: false,
        message: '',
        submitCallback: null,
      },
    }
  },

  methods: {
    showAlertDialog(message, callback, props = null) {
      this.alertProps = {
        show: true,
        message,
        submitCallback: callback || null,
        isHtml: !!(props && props.isHtml),
      }
      return this.alertProps
    },
    showConfirmDialog(message, callback, props = null) {
      this.confirmProps = {
        show: true,
        message,
        submitCallback: callback || null,
        okText: props && props.okText ? props.okText : '확인',
        cancelText: props && props.cancelText ? props.cancelText : '취소',
        isHtml: !!(props && props.isHtml),
      }
      return this.confirmProps
    },
  },
}

export default { alerts }
