import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { callExternalApi } from '@/core/global/global-liveinfo'

export const setUserData = userData => {
  localStorage.setItem('userData', JSON.stringify(userData))

  store.commit('account/setName', userData.userName)
  store.commit('account/setPoint', userData.userPoint)
  store.commit('account/setPhoneNum', userData.phoneNumber)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const changeUserPoint = userPoint => {
  const userData = getUserData()
  userData.userPoint = userPoint

  setUserData(userData)
}

export const clearUserData = async () => {
  if (localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)) {
    try {
      await useJwt.clearServerToken()
    } catch (err) {
      // 서버에서 토큰 제거에 실패하더라도 그대로 진행
    }
  }
  localStorage.removeItem('userData')
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  store.commit('account/clear')

  // 자동로그인을 위해 로컬DB에 저장된 토큰 제거
  saveRefreshToken(null)
}

// 자동 로그인 설정시에만 RefreshToken을 로컬DB에 보관
export const saveRefreshToken = refreshToken => {
  callExternalApi("SaveRefreshToken", refreshToken)
}

export const isUserLoggedIn = async () => {
  try {
    const rs = await useJwt.isLogin()

    if (!rs.data) { return false }

    const { isLogin } = rs.data

    let refreshTokenResult = null

    // if (!isLogin && localStorage.getItem(useJwt.jwtConfig.storageRefreshTokenKeyName)) {
    if (isLogin === false) {
      const ret = await useJwt.refreshTokenLogin()

      if (ret.result === 'refresh' && ret.userData) {
        if (ret.userData.useLongTermToken && ret.refreshToken) saveRefreshToken(ret.refreshToken)

        setUserData(ret.userData)
      } else if (ret.result === 'invalid' || ret.result === 'unauthrized') {
        // 유저 매칭 없음('invalid') 또는 토큰 만료('unauthrized') 시 로그아웃 처리
        clearUserData()
      }

      refreshTokenResult = ret.result
    } else if (isLogin && store.getters['account/getName'] === null) {
      const userData = getUserData()

      setUserData(userData)
    }

    return ((isLogin || refreshTokenResult === 'refresh' || refreshTokenResult === 'dupRequest') && localStorage.getItem('userData') !== null)
  } catch (err) {
    return false
  }
}

export const checkAccessible = () => window.navigator.userAgent.indexOf('DrInfo') !== -1 || !!(process.env && process.env.VUE_APP_LOCAL_ENV)
