export default [
  {
    path: '/moa',
    name: 'moa-main',
    component: () => import('@/views/moa/Moa.vue'),
    meta: {
      layout: 'moa',
    },
  },
]
