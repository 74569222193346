var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.options.show
      ? _c(
          "div",
          {
            staticClass: "layer-popup-alert survey-progress-type",
            staticStyle: { "z-index": "1000", position: "fixed" },
          },
          [
            _c("div", { staticClass: "alert-type-wrap" }, [
              _vm.options.isHtml
                ? _c("p", {
                    staticClass: "txt",
                    domProps: { innerHTML: _vm._s(_vm.options.message) },
                  })
                : _c(
                    "p",
                    { staticClass: "txt" },
                    [
                      _vm._l(_vm.splitMessages, function (msg, index) {
                        return [
                          _vm._v(" " + _vm._s(msg) + " "),
                          _c("br", { key: index }),
                        ]
                      }),
                    ],
                    2
                  ),
              _c("div", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.subMessage,
                    expression: "subMessage",
                  },
                ],
                staticClass: "txt-wrap",
              }),
              _c(
                "div",
                { staticClass: "check-wrap", staticStyle: { display: "none" } },
                [
                  _c("input", {
                    attrs: { id: "allDelete2", type: "checkbox" },
                  }),
                  _c("label", { attrs: { for: "allDelete2" } }, [
                    _vm._v("다시 보지 않기"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "popup-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-line",
                    on: {
                      click: function ($event) {
                        return _vm.submit(false)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.options.cancelText) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-blue",
                    on: {
                      click: function ($event) {
                        return _vm.submit(true)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.options.okText) + " ")]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }