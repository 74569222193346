import Vue from 'vue'

import { alerts } from '@/libs/mixins/alerts'
import { customFilter } from '@/libs/mixins/filters'
import { setInjectInfoData } from '@/common/ysarang/service'
import router from './router'
import store from './store'

import App from './App.vue'

import '@/core/global/receive-liveinfo'
import '@/core/global/global-components'
import '@/core/global/vue-dompurify-html'

Vue.mixin(alerts)
Vue.mixin(customFilter)

require('@/assets/font/feather/iconfont.css')

require('@/assets/scss/style.scss')
require('@/assets/scss/main.scss')
require('@/assets/scss/pre-drinfo.scss')
require('@/assets/scss/gathering-style.scss')

Vue.config.productionTip = false
Vue.config.ignoredElements = ['copyright']

Vue.directive('focus', {
  inserted(el) {
    el.focus()
  }
})

Vue.directive('number', {
  bind(el) {
    el.addEventListener('input', () => {
      // eslint-disable-next-line no-param-reassign
      el.value = el.value.replace(/[^0-9]/gi, '')
    })
  },
})

setInjectInfoData()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
