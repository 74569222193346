<template>
  <transition name="fade">
    <div
      v-if="options.show"
      class="layer-popup-alert survey-progress-type"
      style="z-index: 1000; position: fixed;"
    >
      <div class="alert-type-wrap">
        <!-- v-dompurify-html 사용 시 메시지 창이 닫힐 때 잔상이 남는 문제가 있어 v-html로 사용 -->
        <p
          v-if="options.isHtml"
          class="txt"
          v-html="options.message"
        />
        <p
          v-else
          class="txt"
        >
          <template v-for="(msg, index) in splitMessages">
            {{ msg }} <br :key="index">
          </template>
        </p>
        <div
          v-dompurify-html="subMessage"
          class="txt-wrap"
        />
        <div
          class="check-wrap"
          style="display: none"
        >
          <input
            id="allDelete2"
            type="checkbox"
          >
          <label for="allDelete2">다시 보지 않기</label>
        </div>

        <div class="popup-btn">
          <button
            class="btn-line"
            @click="submit(false)"
          >
            {{ options.cancelText }}
          </button>
          <button
            class="btn-blue"
            @click="submit(true)"
          >
            {{ options.okText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    splitMessages() {
      return this.options.message.split('\n')
    },
    subMessage() {
      return this.options.subMessage
    },
  },
  methods: {
    submit(isOk) {
      if (this.options && this.options.submitCallback) {
        this.options.submitCallback(isOk)
      }

      this.options.show = false
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
