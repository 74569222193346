import { callExternalApi } from '@/core/global/global-liveinfo'
import { setStatistics } from '@/common/statistics/service'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token Call Time
  refreshTokenCallTime = null

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.getRefreshToken()) {
            return Promise.reject(error)
          }
          if (!this.isAlreadyFetchingAccessToken) {
            if (this.refreshTokenCallTime) {
              // 리프레시 토큰 갱신 호출 시점이 30초를 지나지 않은 경우, 의도하지 않은 중복 호출로 간주하여 토큰 갱신을 요청하지 않고 갱신으로 간주하도록 예외처리
              const elapsedSeconds = (Date.now() - this.refreshTokenCallTime) / 1000
              if (elapsedSeconds < 30) return Promise.reject(error) // 에러 그대로 반환
            }

            this.refreshTokenCallTime = Date.now()
            this.isAlreadyFetchingAccessToken = true

            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              if (r.data.refreshToken && r.data.userData.useLongTermToken) callExternalApi("SaveRefreshToken", r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)

              setStatistics(105) // 105: 리프레시토큰_자동갱신_성공
            }).catch(() => {
              this.refreshTokenCallTime = null
              this.isAlreadyFetchingAccessToken = false
              setStatistics(106) // 106: 리프레시토큰_자동갱신_실패
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  isLogin() {
    return this.axiosIns.get(this.jwtConfig.isLoginEndpoint)
  }

  clearServerToken() {
    return this.axiosIns.delete(this.jwtConfig.logoutEndpoint)
  }

  async refreshTokenLogin() {
    try {
      if (!this.getRefreshToken()) {
        return { result: 'failed' }
      }

      if (this.refreshTokenCallTime) {
        // 리프레시 토큰 갱신 호출 시점이 30초를 지나지 않은 경우, 의도하지 않은 중복 호출로 간주하여 토큰 갱신을 요청하지 않고 갱신으로 간주하도록 예외처리
        const elapsedSeconds = (Date.now() - this.refreshTokenCallTime) / 1000
        if (elapsedSeconds < 30) return { result: 'dupRequest' }
      }

      this.refreshTokenCallTime = Date.now()
      const r = await this.refreshToken()

      if (r.data.accessToken && r.data.refreshToken) {
        this.setToken(r.data.accessToken)
        this.setRefreshToken(r.data.refreshToken)
        this.onAccessTokenFetched(r.data.accessToken)

        setStatistics(103) // 103: 리프레시토큰_갱신호출_성공

        return { result: 'refresh', userData: r.data.userData, refreshToken: r.data.refreshToken }
      }
    } catch (err) {
      this.refreshTokenCallTime = null

      if (err.response && err.response.data.error) {
        setStatistics(104) // 104: 리프레시토큰_갱신호출_실패

        if (err.response.status === 400) return { result: 'invalid' }
        if (err.response.status === 401) return { result: 'unauthrized' }
      }
    }
    return { result: 'failed' }
  }
}
